import React from "react";

import "./App.css";
import Logo from "../assets/img/logo.svg";
import Arrow from "../assets/img/arrow.png";
import { usePresenter } from "@hooks/usePresenter";

export const App = () => {
  usePresenter();

  return (
    <div className="container">
      <img src={Logo} alt="logo" className="logo" />
      <img src={Arrow} alt="arrow" className="arrow" />
      <div className="text_box">
        <p>Чтобы подписаться на уведомления, нажми на кнопку "Разрешить" в окне браузера</p>
      </div>
    </div>
  );
};
